import axios from "axios";
import { BASE_URL } from './settings';

const URLS = {
  LOGIN: `${BASE_URL}Auth/login`,
};

const LOCAL_STORAGE_KEY_TOKEN = btoa("token");
const LOCAL_STORAGE_KEY_USER = btoa("user");

export default class AuthService {
  static async login(email, password) {
    const defaultErrorMessage = "Authentication failed.";
    try {
      const response = await axios.post(URLS.LOGIN, { email, password });
      this.saveTokenToStorage(response.data.token);
      this.saveUserToStorage(response.data);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || defaultErrorMessage;
      console.error(error);
      throw new Error(errorMessage);
    }
  }

  static saveTokenToStorage(token) {
    window.localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, btoa(token));
  }

  static saveUserToStorage(user) {
    window.localStorage.setItem(LOCAL_STORAGE_KEY_USER, btoa(JSON.stringify(user)));
  }

  static logout() {
    localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_KEY_USER);
  }

  static getToken() {
    const token = localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN);
    return token ? atob(token) : null;
  }

  static getUser() {
    const user = localStorage.getItem(LOCAL_STORAGE_KEY_USER);
    return user ? JSON.parse(atob(user)) : null;
  }
}