// createAccountService.js
import axios from 'axios';
import { BASE_URL } from './settings';

const URLS = {
  FORGET_PASSWORD: `${BASE_URL}Auth/forgotPassword`,
};

const forgetPassword = async (email) => {
  try {

    console.log('Dados: ', email);
    const response = await axios.post(URLS.FORGET_PASSWORD, email, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error registering user:", error);
    throw error; // Lançar o erro para tratamento na página
  }
};

export { forgetPassword };