import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar/navbar.tsx';
import Home from './pages/Home/home.tsx';
import CreateAccount from './pages/CreateAccount/createAccount.tsx';
import Footer from './components/Footer/footer.tsx';
import Login from './pages/Login/Login.tsx';
import ForgetPassword from './pages/ForgetPassword/forgetPassword.tsx';
import ResetPassword from './pages/ResetPassword/ResetPassword.tsx';
import PexAcademy from './pages/PexAcademy/PexAcademy.tsx';
import CarinhoDeCompra from './pages/CarinhoDeCompra/CarinhoDeCompra.tsx';
import DescricaoProduto from './pages/DescricaoProduto/descricaoProduto.tsx'
import PrivateRoute from './routes/privateRoute.js';
import QuemSomos from './pages/QuemSomos/quemSomos.tsx'
import ProdutoServico from './pages/ProdutoServico/ProdutoServico.tsx'
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();

  // Adiciona uma expressão regular para verificar rotas que começam com "/resetPassword"
  const hideNavbarFooterRoutes = ['/login', '/createAccount', '/forgetPassword', '/resetPassword'];
  const shouldShowNavbarFooter = !hideNavbarFooterRoutes.some(route =>
    location.pathname.toLowerCase().startsWith(route.toLowerCase())
  );

  return (
    <div className="App">
      {shouldShowNavbarFooter && <Navbar />}

      <div className="content">
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* element={<PrivateRoute />} */}
          <Route path="/" element={<Home />} />
          <Route path="/createAccount" element={<CreateAccount />} />
          <Route path="/forgetPassword" element={<ForgetPassword />} />
          <Route path="/resetPassword/:token" element={<ResetPassword />} /> {/* Rota com token */}
          <Route path="/pexAcademy" element={<PexAcademy />} />
          <Route path="/descricaoProduto" element={<DescricaoProduto />} />
          <Route path="/carinhoDeCompra" element={<CarinhoDeCompra />} />
          <Route path="/quemSomos" element={<QuemSomos />} />
          <Route path="/produtoServico" element={<ProdutoServico />} />
        </Routes>
      </div>

      {shouldShowNavbarFooter && <Footer />}
    </div>
  );
}

export default App;