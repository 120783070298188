// createAccountService.js
import axios from 'axios';
import { BASE_URL } from './settings';

const URLS = {
    REGISTER_USER: `${BASE_URL}Produto/compra`,
};

const carinhoDeCompra = async (produtosId, userId) => {
    try {
        const data = {
            produtosId: produtosId,
            userId: userId,
        };

        console.log('Dados enviados para o back-end: ', data);

        const response = await axios.post(URLS.REGISTER_USER, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data;
    } catch (error) {
        console.error("Error registering user:", error);
        throw error;
    }
};


export { carinhoDeCompra };