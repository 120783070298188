import axios from 'axios';
import { BASE_URL } from './settings';

const URLS = {
  GET_INTRODUCTION: `${BASE_URL}Home/obterIntroducaoHome`,
};

const fetchIntroduction = async (token: string) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.get(URLS.GET_INTRODUCTION, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching introduction:", error);
    throw error;
  }
};

export { fetchIntroduction };