import React, { useState, useEffect } from 'react';
import styles from './forgetPassword.module.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { forgetPassword } from '../../service/forgetPasswordService.js';
import { Pagination } from 'swiper/modules';
import Loading from '../../components/Loading/loading.tsx'; // Importação do componente de loading
import CustomButton from '../../components/Button/button.tsx'

const CreateAccount: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true); // Estado de loading começando como true
  const images = ["carousel.jpg", "cadeado.png", "logo_pex.webp"];

  useEffect(() => {
    const fetchData = async () => {
      const token = "token quando tiver um";
      try {
        const data = await fetchIntroduction(token);
        if (data?.success) {
          setCurrentSlide(data.data.subTitulo);
        }
      } catch (error) {
        console.error("Erro ao buscar introdução:", error);
      } finally {
        setLoading(false); // Define loading como false após o carregamento completo
      }
    };

    fetchData(); // Chama a função fetchData
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null); // Limpar erro anterior

    setLoading(true); // Ativa o loading ao iniciar a requisição
    try {
      const response = await forgetPassword(email);
    } catch (error) {
    } finally {
      setLoading(false); // Desativa o loading após a operação
    }
  };

  // Exibe indicador de loading até que o estado de loading seja false
  if (loading) {
    return (
      <div className={styles.loading_screen}>
        <Loading /> {/* Componente de loading */}
      </div>
    );
  }

  return (
    <div className={styles.register_page}>
      <div className={styles.form_section}>
        <div className={styles.cadastro}>
          <p className={styles.login_link} style={{ textAlign: 'left' }}>
            <a href="/login">Voltar</a>
          </p>
          <div >
            <img src="logo_pex.webp" alt="PortFolio Expert" />
          </div>
          <h1>Esqueci a senha</h1>
          <p>Insira seu e-mail para recuperar sua senha</p>

          <div className={styles.containterCadastro}>
            <form onSubmit={handleSubmit}>
              {error && <p className={styles.error_message}>{error}</p>}
              <div className={styles.input_group}>
                <label>E-mail</label>
                <div className={styles.input_container}>
                  <input type="text" placeholder="Digite aqui" value={email} onChange={(e) => setEmail(e.target.value)} />
                  <img src="mensagem.jpeg" alt="Ícone" className={styles.input_icon} />
                </div>
              </div>
              <CustomButton
                className={"mt-3"}
                text="Enviar"
                onClick={() => { }}
                style={{
                  color: 'white',
                  padding: '5px 20px',
                  height: '44px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '0px',
                  alignItems: 'center',
                  boxSizing: 'border-box',
                }}
              />
            </form>
          </div>
        </div>
      </div>

      <div className={styles.image_section}>
        <Swiper
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {images.map((item, index) => (
            <SwiperSlide key={index}>
              <img src={item} alt={`Slide ${index + 1}`} />
              <div className={styles.gradient_overlay}></div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default CreateAccount; // Corrija a exportação para o nome correto