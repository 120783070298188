import React from 'react';
import styles from './button.module.css';

interface ButtonProps {
    text: string;
    onClick: () => void;
    style?: React.CSSProperties;
    className?: string;
}

const Button: React.FC<ButtonProps> = ({ text, onClick, style, className }) => {
    return (
        <button
            className={`${styles.button} ${className}`}
            onClick={onClick}
            style={style}
        >
            {text}
        </button>
    );
};

export default Button;
