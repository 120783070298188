import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styles from './ResetPassword.module.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { resetPassword } from '../../service/resetPasswordService.js';
import { Pagination } from 'swiper/modules';
import Loading from '../../components/Loading/loading.tsx'; // Certifique-se de que o caminho está correto
import CustomButton from '../../components/Button/button.tsx'

const ResetPassword: React.FC = () => {
    const { token } = useParams(); // Captura o token da URL
    const [currentSlide, setCurrentSlide] = useState(0);
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [error, setError] = useState<string | null>(null);
    const [loadingData, setLoadingData] = useState<boolean>(true); // Estado para carregamento de dados
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false); // Estado para carregamento de submissão
    const images = ["carousel.jpg", "cadeado.png", "logo_pex.webp"];

    useEffect(() => {
        fetchSubtext();
    }, []);

    const fetchSubtext = async () => {
        try {
            const data = await fetchIntroduction(token);
            if (data?.success) {
                setCurrentSlide(data.data.subTitulo);
            }
        } catch (error) {
            console.error("Erro ao buscar introdução:", error);
        } finally {
            setLoadingData(false); // Desativa o loading de dados após o carregamento
        }
    };

    const validatePasswords = () => {
        if (password !== confirmPassword) {
            setError("As senhas não coincidem!");
            return false;
        }
        return true;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null); // Limpar erro anterior

        if (!validatePasswords()) return;

        setLoadingSubmit(true); // Ativa o loading de submissão

        try {
            await resetPassword(token, password); // Passa o token capturado da URL
            alert("Senha redefinida com sucesso!"); // Mensagem de sucesso
        } catch (error) {
            console.error("Erro ao redefinir senha:", error);
            setError("Erro ao redefinir senha. Tente novamente.");
        } finally {
            setLoadingSubmit(false); // Desativa o loading de submissão, independentemente do sucesso ou falha
        }
    };

    console.log("Dados:", process.env.PUBLIC_URL);

    return (
        <div className={styles.register_page}>
            {loadingData || loadingSubmit ? (
                <Loading /> // Exibe o loading se qualquer estado de loading for verdadeiro
            ) : (
                <>
                    <div className={styles.form_section}>
                        <div className={styles.cadastro}>
                            <div className={styles.logo}>
                                <img src="../logo_pex.webp" alt="PortFolio Expert" />
                            </div>
                            <h1>Nova senha!</h1>
                            <p>Digite sua nova senha!</p>

                            <div className={styles.containterCadastro}>
                                <form onSubmit={handleSubmit}>
                                    {error && <p className={styles.error_message}>{error}</p>}

                                    <div className={styles.input_group}>
                                        <label>Senha</label>
                                        <div className={styles.input_container}>
                                            <input
                                                type="password"
                                                placeholder="Digite aqui"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                            <img src="../cadeado.jpeg" alt="Ícone" className={styles.input_icon} />
                                        </div>
                                    </div>
                                    <div className={styles.input_group}>
                                        <label>Confirmar senha</label>
                                        <div className={styles.input_container}>
                                            <input
                                                type="password"
                                                placeholder="Digite aqui"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                            <img src="../cadeado.jpeg" alt="Ícone" className={styles.input_icon} />
                                        </div>
                                    </div>
                                    <CustomButton
                                        className={"mt-3"}
                                        text="Entrar"
                                        onClick={() => { }}
                                        style={{
                                            color: 'white',
                                            padding: '5px 20px',
                                            height: '44px',
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginTop: '0px',
                                            alignItems: 'center',
                                            boxSizing: 'border-box',
                                        }}
                                    />
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className={styles.image_section}>
                        <Swiper
                            pagination={{
                                dynamicBullets: true,
                            }}
                            modules={[Pagination]}
                            className="mySwiper"
                        >
                            {images.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <img src={item} alt={`Slide ${index + 1}`} />
                                    <div className={styles.gradient_overlay}></div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </>
            )}
        </div>
    );
};

export default ResetPassword;