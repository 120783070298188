import React, { useState, useEffect } from 'react';
import styles from './PexAcademy.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomButton from '../../components/Button/button.tsx';
import { fetchProdutosPexAcademy } from '../../service/produtosPexAcademy.js';

const PexAcademy: React.FC = () => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const produtosSalvos = JSON.parse(localStorage.getItem('produtoIds') || '[]');

    const [produtos, setProdutos] = useState<Array<{
        id: string;
        nome: string;
        imagemUrl: string;
        valor: number;
        desconto: number;
        pdUs: number;
    }>>([]);

    const produtosPex = async () => {
        const token = "token quando tiver um";

        try {
            const data = await fetchProdutosPexAcademy(token);
            setProdutos(data.data);
            console.log("Produtos do back-end: ", data.data);
        } catch (error) {
            console.error("Erro ao buscar os produtos:", error);
        }
    };


    useEffect(() => {
        produtosPex();
    }, []);

    const handleItemClick = (index: number) => {
        setActiveIndex(index); // Atualiza o índice do item ativo
    };

    const handleComprar = (id: string) => {
        // Recupera a lista de produtos do localStorage ou inicializa como um array vazio
        const produtosSalvos: Array<{
            id: string;
            nome: string;
            imagemUrl: string;
            valor: number;
            desconto: number;
            pdUs: number;
        }> = JSON.parse(localStorage.getItem('produtos') || '[]');

        // Encontra o produto pelo ID
        const produtoSelecionado = produtos.find(produto => produto.id === id);

        if (produtoSelecionado) {
            // Verifica se o produto já está na lista para evitar duplicatas
            if (!produtosSalvos.some(produto => produto.id === produtoSelecionado.id)) {
                produtosSalvos.push(produtoSelecionado); // Adiciona o produto à lista
                localStorage.setItem('produtos', JSON.stringify(produtosSalvos)); // Salva a lista atualizada
                alert(`O produto ${produtoSelecionado.nome} foi adicionado ao carinho.`);
            } else {
                alert(`O produto ${produtoSelecionado.nome} já está no carinho.`);
            }
        } else {
            alert('Produto não encontrado.');
        }
    };

    return (
        <div className={styles.pexAcademy_body}>
            <div className={styles.pexAcademy_registerPage}>
                <div className={styles.pexAcademy_cabecalho}>
                    <h3 className={styles.pexAcademy_tituloCabecalho}>PEX Academy</h3>
                    <p className={styles.pexAcademy_subTituloCabecalho}>
                        Fermentum consectetur ipsum pellentesque mi orci ut viverra. Posuere nibh nullam enim pulvinar neque. Porta cras faucibus eget dictum cursus sed erat vitae rutrum.
                    </p>
                </div>
            </div>

            <div className={styles.pexAcademy_ContainerPesquisa}>
                <div className={styles.pexAcademy_Title}>
                    <h3>Produtos</h3>
                </div>

                <div className={styles.pexAcademy_Container}>
                    <div className={styles.pexAcademy_Pesquisa}>

                        <div className={styles.inputWrapper}>
                            <span className={styles.icon}>
                                <img src="/../../lupa.webp" alt="Lupa" />
                            </span>
                            <input
                                type="text"
                                className={styles.pexAcademy_CampoPesquisa}
                                placeholder="Pesquisar..."
                            />
                        </div>

                        <CustomButton
                            text="Buscar"
                            onClick={() => { }}
                            style={{
                                backgroundColor: '#28a745',
                                color: '#fff',
                                padding: '5px 20px',
                                height: '44px',
                                width: '140px',
                                display: 'flex',
                                marginTop: '0px',
                                alignItems: 'center',
                            }}
                        />
                    </div>

                    <ul className={styles.pexAcademy_Ul_Opcoes_Produtos}>
                        {['Com certificado', 'Curso Combo', 'Curso Individual', 'ScrumStudy'].map((item, index) => (
                            <li key={index}>
                                <a
                                    href="#"
                                    className={`${styles.pexAcademy_Opcoes_Produtos} ${activeIndex === index ? styles.active : ''}`}
                                    onClick={() => handleItemClick(index)}
                                >
                                    {item}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className={`${styles.containerCustom} mt-5`}>

                    <div className="row">
                        {produtos.map(produto => (
                            <div
                                key={produto.id}
                                className="col-12 col-md-4 col-lg-3 mb-4"
                                style={{ border: 'none' }}
                            >
                                <div className="card h-100" style={{ border: 'none', display: 'flex', flexDirection: 'column', overflow: 'hidden', backgroundColor: 'black' }}>
                                    <img
                                        src={produto.imagemUrl}
                                        alt={produto.nome}
                                        className={styles.card_img_top}
                                        style={{
                                            width: '100%',
                                            height: '210px',
                                            borderRadius: '8px',
                                            objectFit: 'cover',
                                            display: 'block',
                                        }}
                                    />
                                    <div className="" style=
                                        {{
                                            backgroundColor: 'black',
                                            color: 'white',
                                            flexGrow: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            textAlign: 'start'
                                        }}>
                                        <div>
                                            <h4 className="card-title" style={{ marginBottom: '24px', marginTop: '24px' }}>{produto.nome}</h4>
                                            <p className="card-text mb-2" style={{ marginBottom: '20px' }}>PDUS: {produto.pdUs}</p>  {/* Descrição acima do preço */}
                                            <div className="d-flex justify-content-between align-items-center" style={{ marginBottom: '30px' }}>
                                                <p className="text-decoration-line-through mb-0" style={{ color: '#5F5F5F', fontSize: '1.4rem' }}>
                                                    <span style={{ fontSize: '1.0rem' }}>R$</span>
                                                    {produto.valor}
                                                </p>
                                                <p className="fw-bold mb-0" style={{ color: 'white', fontSize: '1.4rem', marginLeft: '10px' }}>
                                                    <span style={{ color: 'white', fontSize: '1.0rem' }}>R$</span>
                                                    {produto.desconto}
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <CustomButton
                                                className='mt-3'
                                                text="Comprar"
                                                onClick={() => handleComprar(produto.id)}
                                                style={{
                                                    backgroundColor: '#28a745',
                                                    color: '#fff',
                                                    padding: '5px 20px',
                                                    height: '44px',
                                                    width: '100%',
                                                    display: 'flex',
                                                    marginTop: '0px',
                                                    alignItems: 'center',
                                                    boxSizing: 'border-box'
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </div >
    );
};

export default PexAcademy;