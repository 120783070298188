// createAccountService.js
import axios from 'axios';
import { BASE_URL } from './settings';

const URLS = {
  REGISTER_USER: `${BASE_URL}User/cadastroUsuario`,
};

const registerUser = async (name, email, password, cpf, phone) => {
  try {
    const data = {
      Name: name,
      Email: email,
      Password: password,
      CPF: cpf,
      Phone: phone,
    };

    console.log('Dados enviado para o back-end: ', data);

    const response = await axios.post(URLS.REGISTER_USER, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error registering user:", error);
    throw error; // Lançar o erro para tratamento na página
  }
};

export { registerUser };