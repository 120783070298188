import React from 'react';
import styles from './descricaoProduto.module.css';
import CustomButton from '../../components/Button/button.tsx';
import ButtonWhite from '../../components/ButtonWhite/buttonWhite.tsx'

const DescricaoProduto: React.FC = () => {
    return (
        <div className={styles.container}>
            <nav className={styles.breadcrumb}>
                <span>Home</span> &gt; <span>Pex Academy</span> &gt; <span>Agile + SCRUM</span>
            </nav>

            <div className={styles.productDetails}>
                <div className={styles.imageContainer}>
                    <img
                        src="https://via.placeholder.com/300" // Substitua pelo caminho real da imagem
                        alt="Agile + SCRUM"
                        className={styles.productImage}
                    />
                </div>
                <div className={styles.productInfo}>
                    <h1>Agile + SCRUM - Mindset e framework Ágil</h1>
                    <p className={styles.price}>R$ 640,00</p>
                    <p className={styles.rating}>⭐⭐⭐⭐⭐ (5.0) • 10 reviews</p>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <CustomButton
                            className={`mt-3`}
                            text="Comprar"
                            onClick={() => { }}
                            style={{
                                color: 'white',
                                padding: '5px 20px',
                                height: '44px',
                                width: '32%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxSizing: 'border-box',
                            }}
                        />
                        <ButtonWhite
                            className={`mt-3`}
                            text="Ver grade do curso"
                            onClick={() => { }}
                            style={{
                                color: 'white',
                                padding: '5px 20px',
                                height: '44px',
                                width: '32%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxSizing: 'border-box',
                            }}
                        />
                    </div>

                </div>
            </div>

            <div className={styles.description}>
                <h2>Descrição</h2>
                <p>
                    IA para PMO: Curso Gravado. Este curso estratégico de Inteligência Artificial
                    para Equipes de Projetos e Project Management Office (PMO)/Value Management Office (VMO) visa...
                </p>
            </div>

            <div className={styles.container_newsletter}>
                <div className={styles.newsletter}>
                    <div className={styles.conteudo_newsletter}>
                        <h3>Receba nossas novidades e ofertas exclusivas</h3>
                        <p>Assine nossa newsletter e fique sempre informado sobre todas as novidades em gestão de projetos:</p>
                    </div>
                    <div className={styles.cadastro_newsletter}>
                        <form>
                            <input type="text" placeholder="Seu nome" className={styles.inputField} />
                            <input type="email" placeholder="E-mail" className={styles.inputField} />
                            <CustomButton
                                className={`mt-3`}
                                text="Ver grade do curso"
                                onClick={() => { }}
                                style={{
                                    color: 'white',
                                    padding: '5px 20px',
                                    height: '44px',
                                    width: '100%', /* Botão ocupa largura total */
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    boxSizing: 'border-box',
                                }}
                            />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DescricaoProduto;