import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './navbar.module.css';
import CustomButton from '../Button/button.tsx';
import { Navbar, Nav, Container, Form } from 'react-bootstrap';

function NavScrollExample() {
  const location = useLocation();
  const navigate = useNavigate();

  const links = [
    { href: "/", label: "Home" },
    { href: "/pexAcademy", label: "Pex Academy" },
    { href: "/quemSomos", label: "Quem somos" },
    { href: "/pexConecta", label: "Pex Conecta" },
    { href: "/produtos", label: "Produtos" },
    { href: "/vip", label: "VIP" },
  ];

  return (
    <>
      <Navbar expand="lg" className={`${styles.navbar} ${styles.navbarBgBodyTertiary}`}>
        <Container fluid className={styles.containerMaxWidth}>
          <Navbar.Brand href="#" className={styles.navbarBrand}></Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className={`${styles.navbarNav} mx-auto my-2 my-lg-0`}
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              {links.map(({ href, label }) => (
                <Nav.Link
                  key={href}
                  href={href}
                  className={`${styles.navLink} ${location.pathname === href ? styles.navLinkActive : ''}`}
                >
                  {label}
                </Nav.Link>
              ))}
            </Nav>
            <Form className="d-flex justify-content-center align-items-center">
              {/* Botão do Portal do Cliente */}
              <CustomButton
                text="Portal do cliente"
                onClick={() => { }}
                style={{
                  backgroundColor: '#28a745',
                  color: '#fff',
                  padding: '5px 20px',
                  height: '42px',
                  width: '218px',
                  display: 'flex',
                  margin: 'auto',
                  alignItems: 'center',
                }}
                className="my-custom-class"
              />
              {/* Botão de Perfil */}
              <CustomButton
                text=""
                onClick={() => navigate('/CarinhoDeCompra')}
                style={{
                  backgroundColor: '#28a745',
                  color: '#fff',
                  padding: '5px 20px',
                  height: '36px',
                  width: '36px',
                  display: 'flex',
                  margin: 'auto',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'none',
                  backgroundImage: 'url(/perfil.webp)', // Ajuste do caminho
                  backgroundSize: 'cover',
                }}
                className={styles.my_custom_class}
              />
              {/* Botão de Compra */}
              <CustomButton
                text=""
                onClick={() => navigate('/CarinhoDeCompra')}
                style={{
                  backgroundColor: '#28a745',
                  color: '#fff',
                  padding: '5px 20px',
                  height: '36px',
                  width: '36px',
                  display: 'flex',
                  margin: 'auto',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'none',
                  backgroundImage: 'url(/compra.webp)',
                  backgroundSize: 'cover',
                }}
                className={styles.my_custom_class}
              />
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className={styles.divider}></div>
    </>
  );
}

export default NavScrollExample;