import axios from 'axios';
import { BASE_URL } from './settings';

const URLS = {
    GET_INTRODUCTION: `${BASE_URL}Home/obterProddutoHome`,
};

const produtoHome = async (token: string) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        const response = await axios.get(URLS.GET_INTRODUCTION, config);
        console.log("Chegou aqui: ", response);
        return response.data;
    } catch (error) {
        console.error("Error fetching introduction:", error);
        throw error;
    }
};

export { produtoHome };