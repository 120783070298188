import React, { useState, useEffect } from 'react';
import styles from './ProdutoServico.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomButton from '../../components/Button/button.tsx';
import { fetchProdutosPexAcademy } from '../../service/produtosPexAcademy.js';

const PexAcademy: React.FC = () => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const produtosSalvos = JSON.parse(localStorage.getItem('produtoIds') || '[]');

    const [produtos, setProdutos] = useState<Array<{
        id: string;
        nome: string;
        imagemUrl: string;
        valor: number;
        desconto: number;
        pdUs: number;
    }>>([]);

    const produtosPex = async () => {
        const token = "token quando tiver um";

        try {
            const data = await fetchProdutosPexAcademy(token);
            setProdutos(data.data);
            console.log("Produtos do back-end: ", data.data);
        } catch (error) {
            console.error("Erro ao buscar os produtos:", error);
        }
    };


    useEffect(() => {
        produtosPex();
    }, []);

    const handleItemClick = (index: number) => {
        setActiveIndex(index); // Atualiza o índice do item ativo
    };

    const handleComprar = (id: string) => {
        // Recupera a lista de produtos do localStorage ou inicializa como um array vazio
        const produtosSalvos: Array<{
            id: string;
            nome: string;
            imagemUrl: string;
            valor: number;
            desconto: number;
            pdUs: number;
        }> = JSON.parse(localStorage.getItem('produtos') || '[]');

        // Encontra o produto pelo ID
        const produtoSelecionado = produtos.find(produto => produto.id === id);

        if (produtoSelecionado) {
            // Verifica se o produto já está na lista para evitar duplicatas
            if (!produtosSalvos.some(produto => produto.id === produtoSelecionado.id)) {
                produtosSalvos.push(produtoSelecionado); // Adiciona o produto à lista
                localStorage.setItem('produtos', JSON.stringify(produtosSalvos)); // Salva a lista atualizada
                alert(`O produto ${produtoSelecionado.nome} foi adicionado ao carinho.`);
            } else {
                alert(`O produto ${produtoSelecionado.nome} já está no carinho.`);
            }
        } else {
            alert('Produto não encontrado.');
        }
    };

    return (
        <div className={styles.pexAcademy_body}>
            <div className={styles.pexAcademy_registerPage}>
                <div className={styles.pexAcademy_cabecalho}>
                    <h3 className={styles.pexAcademy_tituloCabecalho}>Produtos e serviços</h3>
                    <p className={styles.pexAcademy_subTituloCabecalho}>
                        Fermentum consectetur ipsum pellentesque mi orci ut viverra. Posuere nibh nullam enim pulvinar neque. Porta cras faucibus eget dictum cursus sed erat vitae rutrum.
                    </p>
                </div>
            </div>

            <div className={styles.pexAcademy_ContainerPesquisa}>
                <div className={styles.pexAcademy_Title} style={{ alignItems: 'center', textAlign: 'center', marginTop: '40px' }} >
                    <h3 style={{ fontSize: '32px' }}>Nossos produtos</h3>
                </div>

                <div className={`${styles.containerCustom} mt-5`}>
                    <div className={styles.produtosContainer} style={{ width: '100%' }}>
                        {produtos.map((produto) => (
                            <div
                                key={produto.id}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    backgroundColor: 'transparent',
                                    borderRadius: '12px',
                                    marginBottom: '20px',
                                    color: 'white',
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    position: 'relative',
                                }}
                            >

                                <div className="containerProduto"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        backgroundColor: '#262525',
                                        borderRadius: '12px',
                                        padding: '20px',
                                        color: 'white',
                                        width: '100%',
                                        position: 'relative',

                                    }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexGrow: 1,
                                            marginRight: '20px',
                                            width: 'auto',
                                            textAlign: 'start',
                                        }}
                                    >
                                        <img
                                            src={produto.imagemUrl}
                                            alt={produto.nome}
                                            style={{
                                                width: '273px',
                                                height: '214px',
                                                objectFit: 'cover',
                                                borderRadius: '8px',
                                                marginRight: '20px',
                                            }}
                                        />

                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                alignItems: 'start',
                                                flexGrow: 1,
                                            }}
                                        >
                                            <h4 style={{ margin: '0', fontSize: '1.8rem' }}>{produto.nome}</h4>
                                            <p
                                                style={{
                                                    margin: '5px 0',
                                                    fontSize: '1rem',
                                                    color: '#aaaaaa',
                                                }}
                                            >
                                                {produto.descricao}
                                            </p>
                                            <span>PDUS: {produto.pdUs}</span>
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            textAlign: 'right',
                                        }}
                                    >
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>


                </div>

            </div>

            <div className={styles.pexAcademy_ContainerPesquisa}>
                <div className={styles.pexAcademy_Title} style={{ alignItems: 'center', textAlign: 'center', marginTop: '40px' }} >
                    <h3 style={{ fontSize: '32px' }}>Nossos serviços</h3>
                </div>

                <div className={`${styles.containerCustom} mt-5`}>

                    <div className="row">
                        {produtos.map(produto => (
                            <div
                                key={produto.id}
                                className="col-12 col-md-4 col-lg-3 mb-4"
                                style={{ border: 'none', backgroundColor: '#262525', borderRadius: '12px' }}
                            >
                                <div className="card h-100" style={{ border: 'none', display: 'flex', flexDirection: 'column', overflow: 'hidden', backgroundColor: '#262525', alignItems: 'center', textAlign: 'center' }}>
                                    <img
                                        src={produto.imagemUrl}
                                        alt={produto.nome}
                                        className={styles.card_img_top}
                                        style={{
                                            width: '98%',
                                            height: '230px',
                                            borderRadius: '12px',
                                            objectFit: 'cover',
                                            display: 'block',
                                            marginTop: '20px'
                                        }}
                                    />
                                    <div className="" style=
                                        {{
                                            backgroundColor: '#262525',
                                            color: 'white',
                                            flexGrow: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            textAlign: 'start'
                                        }}>
                                        <div>
                                            <h4 className="card-title" style={{ marginBottom: '24px', marginTop: '24px' }}>{produto.nome}</h4>
                                            <p className="card-text mb-2" style={{ marginBottom: '20px' }}>PDUS: {produto.pdUs}</p>  {/* Descrição acima do preço */}
                                        </div>
                                        <div>
                                            <CustomButton
                                                className='mt-3'
                                                text="Saiba mais"
                                                onClick={() => handleComprar(produto.id)}
                                                style={{
                                                    backgroundColor: '#28a745',
                                                    color: '#fff',
                                                    padding: '5px 20px',
                                                    height: '44px',
                                                    width: '100%',
                                                    display: 'flex',
                                                    marginTop: '0px',
                                                    alignItems: 'center',
                                                    boxSizing: 'border-box',
                                                    marginBottom: '20px'
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </div >
    );
};

export default PexAcademy;