// createAccountService.js
import axios from 'axios';
import { BASE_URL } from './settings';

const URLS = {
    RESET_PASSWORD: `${BASE_URL}Auth/resetPassword`,
};

const resetPassword = async (resetToken, newPassword) => {
    try {
        const data = {
            ResetToken: resetToken,
            NewPassword: newPassword,
        };
        console.log('Dados do reset', data);
        const response = await axios.post(URLS.RESET_PASSWORD, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data;
    } catch (error) {
        console.error("Error registering user:", error);
        throw error; // Lançar o erro para tratamento na página
    }
};

export { resetPassword };