// src/components/Loading.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import './loading.css'; // Adicione estilos personalizados, se necessário

const Loading = () => {
  return (
    <div className="loading-container">
      <FontAwesomeIcon icon={faCircleNotch} pulse size="3x" />
      <h2>Carregando...</h2>
    </div>
  );
};

export default Loading;
